.ace_editor,
.ace_editor * {
  font-family: "Monaco", "Menlo", "Ubuntu Mono", "Droid Sans Mono", "Consolas",
    monospace !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
}
 .new_questionnair_row{
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: -5px;
    margin-bottom: -8px;
}

.validated
{
     -moz-border-radius: 6px;
     -webkit-border-radius: 6px;
     background-color: #f0fbf1;
     border: solid 1px #34db37;
     border-radius: 6px;
     line-height: 18px;
     overflow: hidden;
     padding: 12px;
     color: #1b6e1c;
     margin-top:10px ;
}

.error
{
     -moz-border-radius: 6px;
     -webkit-border-radius: 6px;
     background-color: #fbf0f0;
     border: solid 1px #db3434;
     border-radius: 6px;
     line-height: 18px;
     overflow: hidden;
     padding: 12px;
     margin-top: 10px;
     color: #db3434;
}
